import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Chat from "./screens/chat";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { FaRegCheckCircle } from "react-icons/fa";

function App() {
  return (
    <>
      <Chat />
      <ToastContainer
        autoClose={2000}
        hideProgressBar={true}
        theme="dark"
        toastStyle={{ backgroundColor: "#044557" }}
      />
    </>
  );
}

export default App;
